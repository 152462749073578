import { LoginForm } from '@desquared/wave-components-library';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { loginV3, loginV3external } from '@/api/register';
import { CompleteProfileIDStore } from '@/store/completeProfileId';
import { OTPIDStore } from '@/store/otpid';
import { LoginProviderStore } from '@/store/loginProvider';
import User from '@/store/user';
import GuestUserStore from '@/store/guestUser';
import LoaderStore from '@/store/loader';
import { createTokenCookie } from '@/utils/cookies';
import { useTranslations } from 'next-intl';
import { updateUser as updateUserInContactPigeon } from '@/utils/contactPigeon';
import { CountryCodeStore } from '@/store/countryCode';
import CountryCodeAside from '@/components/Aside/CountryCodeAside';
import { CountryCodeSideSheetStore } from '../../store/sideSheet';
import useDeliveryServices from '@/hooks/useDeliveryServices';
import { setShopId } from '@/utils/products/products';
import { getShopsV3 } from '@/api/Shops';
import { SelectedShopId } from '@/store/isAddressRegistered';
import { Shop } from '@/store/shop';
import { IsFromLoginStore } from '@/store/isFromLogin';
import styles from './Login.module.css';

export default function LoginPage({ config, logo, type }) {
  const [password, setPassWord] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [mobileErrorText, setMobileErrorText] = useState('');
  const [hasBottomError, setHasBottomError] = useState(false);
  const [bottomErrorText, setBottomErrorText] = useState('');
  const [hasTopError, setHasTopError] = useState(false);
  const [topErrorText, setTopErrorText] = useState('');
  const [hasEmail, setHasEmail] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const router = useRouter();
  const { getOrFetchDeliveryServices, setSelectedUserAddressIndex, selectedService, selectedTakeAwayStoreIndex } =
    useDeliveryServices();

  const t = useTranslations('login_register');
  const r = useTranslations('common');

  const userConfig = config?.fields?.user;

  useEffect(() => {
    const CountryCodeStore$ = CountryCodeStore.subscribe(code => setCountryCode(code));

    return () => {
      CountryCodeStore$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setHasEmail(!config.auth.methods.some(el => el.method === 'MOBILE_PHONE' && el.active));
    const externalLogin = async (accessToken, provider) => {
      LoaderStore.setIsVisible(true);
      const { data, error } = await loginV3external(provider, accessToken);
      if (!data) {
        LoaderStore.setIsVisible(false);
      }
      if (error) {
        setHasTopError(true);
        setTopErrorText(t('errors.login.unknown'));
        LoaderStore.setIsVisible(false);
      } else {
        LoginProviderStore.update(provider);
        if (data.status === 'NEED_TO_COMPLETE_PROFILE') {
          CompleteProfileIDStore.update(data.completeProfileRequestId);
          const params = { pageFrom: 'login' };
          User.update({
            ...data.user,
          });
          router.push(
            {
              pathname: '/account-details',
              query: params,
            },
            undefined,
            { shallow: false }
          );
        } else if (data.status === 'SUCCESS') {
          createTokenCookie(data.authToken.token);
          User.update({
            ...data.user,
            authToken: data.authToken.token,
          });
          if (process.env.hasContactPigeon) {
            updateUserInContactPigeon(data.user.email);
          }
          if (process.env.hasStoreNecessary && (!data?.user?.addresses || data?.user?.addresses?.length === 0)) {
            LoaderStore.setIsVisible(false);
            router.push('/select-service');
            return;
          }
          if (process.env.hasStoreNecessary && data?.user?.addresses && data?.user?.addresses?.length > 0) {
            const services = await getOrFetchDeliveryServices();
            const deliveryService = services.find(entry => entry.deliversToAddress === true);
            const takeAwayService = services.find(entry => entry.deliversToAddress === false);
            // const shop = await setShopId(data?.user?.addresses[0]?.postalCode, deliveryService?.id);
            let shop = undefined;
            const addresses = data?.user?.addresses || [];
            for (let index = 0; index < addresses.length; index++) {
              const address = addresses[index];
              if (deliveryService?.deliversToAddress || !deliveryService) {
                shop = await setShopId(address?.postalCode, deliveryService?.id);
                if (shop) {
                  setSelectedUserAddressIndex(index);
                  break; // Stop the loop if a valid shop is found
                }
              }
            }
            if (!deliveryService?.deliversToAddress || !selectedService) {
              shop = await getShopsV3(deliveryService?.id, null);
              if (selectedTakeAwayStoreIndex) {
                if (shop?.shops?.[selectedTakeAwayStoreIndex]?._id) {
                  SelectedShopId.update(shop.shops[selectedTakeAwayStoreIndex]._id);
                  Shop.setSelectedShop(shop.shops[selectedTakeAwayStoreIndex]);
                } else {
                  LoaderStore.setIsVisible(false);
                  router.push('/select-service');
                  return;
                }
              } else {
                if (shop?.shops?.[0]?._id) {
                  SelectedShopId.update(shop.shops[0]._id);
                  Shop.setSelectedShop(shop.shops[0]);
                } else {
                  LoaderStore.setIsVisible(false);
                  router.push('/select-service');
                  return;
                }
              }
            }
            if (!shop && selectedService.deliversToAddress) {
              LoaderStore.setIsVisible(false);
              router.push('/select-service');
              return;
            }
          }
          IsFromLoginStore.update(true);
          router.push({
            pathname: '/',
          });
        } else if (data.status === 'NEED_VERIFICATION') {
          OTPIDStore.update({ requestId: data.otpInfo.requestId, email: email });
          User.update({
            ...data.user,
            email: email,
            mobile: mobile,
          });
          const params = { pageFrom: 'login' };
          router.push(
            {
              pathname: '/otp',
              query: params,
            },
            undefined,
            { shallow: false }
          );
        }
      }

      LoaderStore.setIsVisible(false);
    };
    if (router.isReady && router.query?.accessToken && router.query?.provider && router.query?.status) {
      if (router.query.status === 'SUCCESS') {
        externalLogin(router.query.accessToken, router.query.provider);
      }
    } else {
      LoaderStore.setIsVisible(false);
    }
  }, []);

  const onCountryCodeClick = () => {
    CountryCodeSideSheetStore.open();
  };

  const onEmailChange = e => {
    setEmail(e.currentTarget.value);
    setEmailErrorText('');
    setHasBottomError(false);
  };
  const onMobileChange = e => {
    setMobile(e.currentTarget.value);
    setMobileErrorText('');
    setHasBottomError(false);
  };

  const onPassWordChange = e => {
    setPassWord(e.currentTarget.value);
    setHasBottomError(false);
  };

  const formSubmit = async () => {
    GuestUserStore.clear();
    LoaderStore.setIsVisible(true);
    const newMobile = countryCode[0] + mobile;
    let body;
    if (!hasEmail) {
      body = {
        mobilePhone: newMobile,
      };
    } else {
      body = {
        email: email,
        password: password,
      };
    }
    const { data, error } = await loginV3(body);
    if (error) {
      LoaderStore.setIsVisible(false);
      if (error.code === 'AUTH0010') {
        setEmailErrorText(t('errors.login.email_not_found'));
        setMobileErrorText(t('errors.login.mobile_not_found'));
        return;
      } else if (error.code === 'AUTH0011') {
        setHasBottomError(true);
        setBottomErrorText(t('errors.login.wrong_email_or_password'));
        return;
      }
      setHasBottomError(true);
      setBottomErrorText(t('errors.login.unknown'));
    } else {
      if (data.status === 'NEED_TO_COMPLETE_PROFILE') {
        CompleteProfileIDStore.update(data.completeProfileRequestId);
        const params = { pageFrom: 'login' };
        User.update({
          ...data.user,
        });
        router.push(
          {
            pathname: '/account-details',
            query: params,
          },
          undefined,
          { shallow: false }
        );
      } else if (data.status === 'SUCCESS') {
        createTokenCookie(data.authToken.token);
        User.update({
          ...data.user,
          authToken: data.authToken.token,
        });
        if (process.env.hasContactPigeon) {
          updateUserInContactPigeon(data.user.email);
        }
        if (process.env.hasStoreNecessary && (!data?.user?.addresses || data?.user?.addresses?.length === 0)) {
          LoaderStore.setIsVisible(false);

          router.push('/select-service');
          return;
        }
        if (process.env.hasStoreNecessary && data?.user?.addresses && data?.user?.addresses?.length > 0) {
          const services = await getOrFetchDeliveryServices();
          const deliveryService = services.find(entry => entry.deliversToAddress === true);
          const takeAwayService = services.find(entry => entry.deliversToAddress === false);
          // const shop = await setShopId(data?.user?.addresses[0]?.postalCode, deliveryService?.id);
          let shop = undefined;
          const addresses = data?.user?.addresses || [];
          for (let index = 0; index < addresses.length; index++) {
            const address = addresses[index];
            if (selectedService?.deliversToAddress || !selectedService) {
              shop = await setShopId(address?.postalCode, deliveryService?.id);
              if (shop) {
                setSelectedUserAddressIndex(index);
                break; // Stop the loop if a valid shop is found
              }
            }
          }
          if (!selectedService.deliversToAddress || !selectedService) {
            shop = await getShopsV3(selectedService?.id, null);
            if (selectedTakeAwayStoreIndex) {
              if (shop?.shops?.[selectedTakeAwayStoreIndex]?._id) {
                SelectedShopId.update(shop.shops[selectedTakeAwayStoreIndex]._id);
                Shop.setSelectedShop(shop.shops[selectedTakeAwayStoreIndex]);
              } else {
                LoaderStore.setIsVisible(false);
                router.push('/select-service');
                return;
              }
            } else {
              if (shop?.shops?.[0]?._id) {
                SelectedShopId.update(shop.shops[0]._id);
                Shop.setSelectedShop(shop.shops[0]);
              } else {
                LoaderStore.setIsVisible(false);
                router.push('/select-service');
                return;
              }
            }
          }
          if (!shop && selectedService.deliversToAddress) {
            LoaderStore.setIsVisible(false);
            router.push('/select-service');
            return;
          }
        }
        IsFromLoginStore.update(true);
        router.push({
          pathname: '/',
        });
      } else if (data.status === 'NEED_VERIFICATION') {
        OTPIDStore.update({ requestId: data.otpInfo.requestId, email: email, mobilePhone: !hasEmail ? newMobile : '' });
        User.update({
          email: email,
          mobilePhone: !hasEmail ? newMobile : '',
        });
        const params = { pageFrom: 'login' };
        router.push(
          {
            pathname: '/otp',
            query: params,
          },
          undefined,
          { shallow: false }
        );
      }

      LoaderStore.setIsVisible(false);
    }
  };

  const onSocialClick = async provider => {
    // LoaderStore.setIsVisible(true);
    window.location.href =
      router.locale === router.defaultLocale
        ? `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/auth/provider/${provider}/initiate?clientRedirectionUrl=${process.env.NEXT_PUBLIC_HOSTNAME}login&tenant=${process.env.tenant}`
        : `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/auth/provider/${provider}/initiate?clientRedirectionUrl=${process.env.NEXT_PUBLIC_HOSTNAME}/${router.locale}/login&tenant=${process.env.tenant}`;
  };

  const onRegisterClick = () => {
    router.push('/register');
  };
  let hasFacebook = false;
  let hasGoogle = false;
  let hasApple = false;
  config?.auth?.providers.map(entry => {
    if (entry.provider === 'FACEBOOK') {
      hasFacebook = entry?.active ?? false;
    } else if (entry.provider === 'GOOGLE') {
      hasGoogle = entry?.active ?? false;
    } else if (entry.provider === 'APPLE') {
      hasApple = entry?.active ?? false;
    }
  });
  const hasExternal = hasFacebook || hasGoogle || hasApple;
  return (
    <div>
      <LoginForm
        logo={logo}
        logoRedirect={router.locale === router.defaultLocale ? '/' : `/${router.locale}/`}
        onEmailChange={onEmailChange}
        onMobileChange={onMobileChange}
        onPassWordChange={onPassWordChange}
        emailValue={email}
        mobileValue={mobile}
        passwordValue={password}
        registerRedirect={onRegisterClick}
        hasFacebook={hasFacebook}
        hasApple={hasApple}
        hasGoogle={hasGoogle}
        hasEmail={hasEmail}
        emailErrorText={emailErrorText ?? emailErrorText}
        emailHelperTextType={emailErrorText ? 'error' : 'helper'}
        mobileHelperTextType={mobileErrorText ? 'error' : 'helper'}
        mobileErrorText={mobileErrorText ?? mobileErrorText}
        // passwordErrorText={passwordErrorText ?? passwordErrorText}
        titleText={t('login_title')}
        passwordHelperTextType={'helper'}
        googleButtonText={t('oauth.login.google')}
        appleButtonText={t('oauth.login.apple')}
        facebookButtonText={t('oauth.login.facebook')}
        connectionText={!hasExternal ? '' : hasEmail ? t('connection.login') : t('connection.login_mobile')}
        emailPlaceHolderText={t('email_placeholder')}
        passwordPlaceHolderText={t('password_placeholder')}
        mobilePlaceHolderText={t('email_placeholder')}
        mobileLabelText={t('mobile_placeholder')}
        connectText={t('connect')}
        accountText={t('account_reminder')}
        continueText={t('continue')}
        onGoogleClick={() => onSocialClick('GOOGLE')}
        onFacebookClick={() => onSocialClick('FACEBOOK')}
        onAppleClick={() => onSocialClick('APPLE')}
        hasBottomError={hasBottomError}
        bottomErrorText={bottomErrorText}
        onSubmitForm={formSubmit}
        forgotPasswordText={hasEmail ? t('forgot_password') : ''}
        forgotPasswordLink={
          router.locale === router.defaultLocale ? '/reset-password' : `/${router.locale}/reset-password`
        }
        // forgotPasswordLink={'/reset-password'}
        noAccountText={t('no_account')}
        registerText={t('register')}
        guestText={process.env.hasGuest ? t('guest_text') : ''}
        hasTopError={hasTopError}
        topErrorText={topErrorText}
        onGuestClick={() => router.push('/guest-login')}
        onCodeClick={onCountryCodeClick}
        onOpenSideClick={onCountryCodeClick}
        selectedCodeIndex={0}
        countryCodeLabel={t('country_code')}
        countries={countryCode}
        classOption={type === 1 || type === 2 ? styles.paddingContainer : styles.noPaddingContainer}
        passwordlLabelText={t('password_label')}
        minLength={userConfig?.email?.minLength}
        maxLength={userConfig?.email?.maxLength}
        minLengthErrorText={r('minimun_character')}
      />
      <CountryCodeAside />
    </div>
  );
}
