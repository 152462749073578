import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import isEmpty from 'lodash/isEmpty';

import { getCMSHomepageGrid } from '@/api/cms';
import { getOrFetchHeaderData } from '@/utils/headerCalls';
import LoginPage from '@/components/Login/Login';
import { getOrFetchConfiguration } from '@/utils/getConfiguration';
import LoginLayout from '@/components/LoginLayout/LoginLayout';
import { getLayoutProps } from '@/utils/layout/layout';

export default function Login({ logo, loginLogo, loginConfig, loginImage, responsiveLogo }) {
  const [config, setConfig] = useState();
  const router = useRouter();
  loginImage === '' ? (loginConfig = 0) : null;
  const cookies = new Cookies();
  const authorization = cookies.get('token');

  useEffect(() => {
    async function getConfiguration() {
      const configuration = await getOrFetchConfiguration();
      setConfig(configuration);
      return;
    }
    getConfiguration();
    if (!isEmpty(authorization)) {
      router.replace('/');
      return;
    }
  }, []);
  if (!isEmpty(authorization)) return null;
  return (
    <>
      {config && (
        <LoginLayout type={loginConfig} image={loginImage} responsiveLogo={responsiveLogo}>
          <LoginPage config={config} logo={loginLogo ? loginLogo : logo} type={loginConfig} />
        </LoginLayout>
      )}
    </>
  );
}

export async function getStaticProps(context) {
  const { responsiveLogo } = await getLayoutProps({ headerProps: { selectedIndex: 0 } }, context.locale);

  const [{ data: logoData }, { data: loginLogoData }, { data: loginAllData }] = await Promise.all([
    getCMSHomepageGrid('header_logo', context.locale),
    getOrFetchHeaderData('login_logo', context.locale),
    getOrFetchHeaderData('login_page', context.locale),
  ]);
  const logo = logoData.data[0].content.logo.url;
  const loginLogo = loginLogoData.data[0].content.login_logo.url;
  const loginConfig = loginAllData.data[0].content.configuration;
  const loginImage = loginAllData.data[0].content.image?.url ? loginAllData.data[0].content.image?.url : '';

  const messages = {
    common: (await import(`../../../prebuild-data/i18n/${context.locale}/common.json`)).default,
    login_register: (await import(`../../../prebuild-data/i18n/${context.locale}/login_register.json`)).default,
  };

  return {
    props: {
      responsiveLogo,
      logo,
      loginLogo,
      loginConfig,
      loginImage,
      messages,
    },
  };
}
