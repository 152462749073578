import { BehaviorSubject } from 'rxjs';

const OTPIDSubject = new BehaviorSubject({ requestId: '', email: '' });
const RegisterFlowSubject = new BehaviorSubject(false);

export const OTPIDStore = {
  update: value => {
    OTPIDSubject.next(value);
  },
  subscribe: setState => OTPIDSubject.subscribe(setState),
  getValue: () => OTPIDSubject.value,
};

export const RegisterFlowStore = {
  update: value => {
    RegisterFlowSubject.next(value);
  },
  subscribe: setState => RegisterFlowSubject.subscribe(setState),
  getValue: () => RegisterFlowSubject.value,
};

export default { OTPIDStore, RegisterFlowStore };
