import { waveV3 } from '@/api/config/index.js';

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/auth`;

export async function registerV3(body) {
  try {
    const response = await waveV3.post('/auth/registration', { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error?.response?.data };
  }
}

export async function verifyOTP(body) {
  try {
    const response = await waveV3.post('/auth/verify-otp', { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error?.response?.data };
  }
}

export async function resendOTP(body) {
  try {
    const response = await waveV3.post('/auth/resend-otp', { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error.response.data };
  }
}

export async function completeProfile(body) {
  try {
    const response = await waveV3.post('/auth/complete-profile', { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error?.response?.data ? error.response.data : error.response };
  }
}

export async function loginV3(body) {
  try {
    const response = await waveV3.post('/auth/login', { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error.response.data };
  }
}

export async function loginV3external(provider, accessToken) {
  try {
    const response = await waveV3.post(`/auth/provider/${provider}/login`, {
      accessToken: accessToken,
    });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error };
  }
}

export async function forgotPassword(email) {
  try {
    const response = await waveV3.post(`/auth/forgot-password`, {
      email: email,
    });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error.response.data };
  }
}
export async function changePassword(body) {
  try {
    const response = await waveV3.post(`${baseURL}/change-password`, { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error?.response?.data };
  }
}

export async function resetPassword(body) {
  try {
    const response = await waveV3.post(`/auth/reset-password`, { ...body });
    const data = response.data;
    return { data };
  } catch (error) {
    return { error: error.response.data };
  }
}
