import React from 'react';
import Head from 'next/head';
import cn from 'classnames';
import styles from './LoginLayout.module.css';

export default function LoginLayout({ children, type, image, responsiveLogo }) {
  return (
    <>
      <Head>
        <title>{process.env.displayName}</title>
        <meta name="description" content={`App for ${process.env.displayName} by Wave Grocery`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={responsiveLogo} />
      </Head>

      <div
        className={cn(
          { [styles.defaultContainer]: type === 0 },
          { [styles.withImageContainer]: type === 1 || type === 2 }
        )}
      >
        {image.length > 0 && type === 1 && (
          <div className={styles.imageContainer}>
            <img className={styles.imageStyle} src={image} alt={image} />
          </div>
        )}
        <div
          className={cn(
            { [styles.centerContainer]: type === 0 },
            { [styles.leftContainer]: type === 1 },
            { [styles.rightContainer]: type === 2 }
          )}
        >
          {children}
        </div>
        {image.length > 0 && type === 2 && (
          <div className={styles.imageContainer}>
            <img className={styles.imageStyle} src={image} alt={image} />
          </div>
        )}
      </div>
    </>
  );
}
