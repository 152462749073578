import { BehaviorSubject } from 'rxjs';

const CompleteProfileIDSubject = new BehaviorSubject({ requestId: '', email: '' });

export const CompleteProfileIDStore = {
  update: value => {
    CompleteProfileIDSubject.next(value);
  },
  subscribe: setState => CompleteProfileIDSubject.subscribe(setState),
  getValue: () => CompleteProfileIDSubject.value,
};

export default { CompleteProfileIDStore };
